import { Button } from "@chakra-ui/button"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import EditWindow from "../components/admin/EditWindow"
import GeneralInformationEditor from "../components/kioskEditor/generalInformationEditor"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import useIsAdmin from "../hooks/useisAdmin"
import { userFetch } from "../utility/userFetch"
import Login from "./../pages/login"
const Versberichten = React.lazy(
  () => import("../components/admin/VersBerichten")
)

export default function Admin({ data, pageContext, location }: any) {
  const { tags } = pageContext

  const hasAccess = useIsAdmin()
  const toast = useToast()
  const addNew = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const { state } = useContext(UserContext)

  const onSubmit = (data: any) => {
    setIsLoading(true)

    userFetch(
      "POST",
      "AddNew",
      state!.jwt,
      JSON.stringify({
        ...data,
        products: Array.isArray(data.products)
          ? data.products.map((x: any) => x.value)
          : [],
      })
    )
      .then(a => {
        navigate("/mutate/?id=" + a.rowKey)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const form = useForm()
  if (hasAccess === false) {
    return <Login location={location} noRedirect={true} />
  }

  if (!hasAccess) {
    return (
      <SimpleLayout title="loading" location={location}>
        ...
      </SimpleLayout>
    )
  }

  return (
    <SimpleLayout
      title="Stalletjes langs de weg - Alle kraampjes aan de straat"
      location={location}
    >
      <Modal onClose={addNew.onClose} size="full" isOpen={addNew.isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <GeneralInformationEditor
              isLoading={isLoading}
              products={tags}
              formControl={form}
              onSubmit={onSubmit}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={addNew.onClose}>Sluit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button onClick={addNew.onOpen}>Voeg stalletje toe</Button>

      <br />
      <br />
      <br />

      <EditWindow buttonText="Versberichten">
        <Versberichten />
      </EditWindow>
    </SimpleLayout>
  )
}
