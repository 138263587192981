import { Button } from "@chakra-ui/react"
import React, { Suspense, useState } from "react"

export default function EditWindow({
  children,
  buttonText,
}: {
  children: any
  buttonText: string
}) {
  const [isOpen, setIsOpen] = useState(false)
  if (isOpen) {
    return <Suspense fallback="🔥">{children}</Suspense>
  }

  return (
    <Suspense fallback="🔥">
      <Button onClick={() => setIsOpen(true)}>{buttonText}</Button>
    </Suspense>
  )
}
